<template>
  <div>
    <b-skeleton
      v-if="loading"
      class="height-200"
    />
    <div v-else>
      <div v-if="!skipTexts && isAssignment" class="row">
        <div class="col-12">
          <h4>{{ $t("games.common.task") }}</h4>
        </div>
      </div>
      <hr v-if="!skipTexts && isAssignment" class="m-t-0"/>
      <div class="row m-t-10">
        <div class="col-12">
          <div class="alert alert-secondary" role="alert">
            <img
              v-if="currentGame === 'flags'"
              :src="currentTask"
              width="50%"
              alt=""
            />
            <h1 v-else class="m-0" v-html="currentTask"/>
          </div>
        </div>
      </div>
      <hr v-if="!skipTexts" class="m-t-0"/>
      <h5 v-if="!skipTexts">{{ $t("games.common.chooseCorrectAnswer") }}</h5>
      <MathResults
        :task-id="currentTaskId"
        :game="currentGame"
        :results="allResults"
      />
      <hr class="m-t-0"/>
      <h5 v-if="!skipTexts">{{ $t("assignments.tasks") }}</h5>
      <MathGameTiles
        v-if="tasks"
        :tasks="tasks"
        :current-task-idx="currentTaskIdx"
      />
      <GameActions
        game="math"
        :math-game="game"
        :showed-actions="showedActions"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import katex from "katex";
import 'katex/dist/katex.min.css';
import MathResults from "@/pages/games/education/MathResults.vue";
import MathGameTiles from "@/components/games/MathGameTiles.vue";
import GameActions from "@/components/games/GameActions.vue"; // Import the KaTeX CSS for styling

export default {
  name: "MathGridContent",
  components: {GameActions, MathGameTiles, MathResults},
  props: {
    game: {
      type: String,
      default: ""
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
    isAssignment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("mathStore", {
      loading: (state) => state.loading,
      tasks: (state) => state.tasks,
      currentTaskIdx: (state) => state.currentTaskIdx,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
    }),
    currentGame() {
      return this.tasks[this.currentTaskIdx]?.game || this.game
    },
    currentTaskId() {
      if(this.currentTaskIdx === -1) {
        return '';
      }
      return this.tasks[this.currentTaskIdx].taskId;
    },
    currentTask() {
      if(this.currentTaskIdx === -1) {
        return;
      }

      const task = this.tasks[this.currentTaskIdx].task;

      if(this.currentGame === "flags") {
        return require("@/assets/images/flags/" + task + ".png")
      }
      if(this.currentGame === "cities"){
        return this.$t("countries." + task);
      }

      const task2 = task.replace("|||of|||", this.$t("games.games.percentage.of"))

      try {
        return katex.renderToString(task2, {output: "html"});
      } catch (error) {
        return task2;
      }
    },
    allResults() {
      if(this.currentTaskIdx === -1) {
        return [];
      }
      const task = this.tasks[this.currentTaskIdx]
      if(["roman", "time", "distance", "mass", "surface", "volumeliquids", "volumeobjects", "flags", "cities", "equationssimple", "equationsmultiple", "fractions"].includes(this.game)) {
        return [
          ...task.answers.wrong,
          task.answers.correct
        ].sort();
      }
      return [
        ...task.answers.wrong,
        task.answers.correct
      ].sort((a,b) => a-b);
    },
    skipTexts() {
      return "skipTexts" in this.$route.query && this.$route.query.skipTexts;
    },
  },
  methods: {
    handleKeyPress(e) {
      e.preventDefault();
      const upperCaseKey = e.key.toUpperCase();
      if (upperCaseKey === "ARROWLEFT") {
        this.$store.commit("mathStore/goToPreviousTask");
      } else if (upperCaseKey === "ARROWRIGHT") {
        this.$store.commit("mathStore/goToNextTask");
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
}
</script>